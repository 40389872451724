import React from 'react';
import pizzaSlice from '../assets/pizzaSlice.png';

function Footer() {
  return (
    <div className="footer" id="footer">
      
      <div className="footer-copyright">
        <i className="fa-solid fa-pizza-slice"></i><span> ReactJSPizza</span>
      </div>
      <div className="footer-logo">
        <img src={pizzaSlice} />
        <span>© 2024</span>
      </div>
      <div className="footer-social">
        <ul>
          <a href="#"><li><i className="fa-brands fa-linkedin"></i></li></a>
          <a href="#"><li><i className="fa-brands fa-instagram"></i></li></a>
          <a href="#"><li><i className="fa-brands fa-discord"></i></li></a>
          <a href="#"><li><i className="fa-solid fa-envelope"></i></li></a>
        </ul>
      </div>

    </div>
  )
}

export default Footer
