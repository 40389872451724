import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Homepage from './pages/Homepage';
import Menu from './pages/Menu';
import About from './pages/About';
import Contact from './pages/Contact';
import NotFound from './helpers/NotFound';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import pizzaImg from './assets/pizza.png'
import NotResponsive from './components/NotResponsive';


function App() {
  let loadingOverlay = () => {
    setTimeout(() => {
      document.getElementById("loadingOverlay").style.display = "flex";
    }, 0);
    setTimeout(() => {
      document.getElementById("App").style.display = "unset";
      document.getElementById("loadingOverlay").style.display = "none";
    }, 2000);
  }
  document.body.onload = loadingOverlay();
  return (
    <>
      <div className="App" id="App">
        <Router>
          <Navbar />
          <Routes>
            <Route path="*" exact element={<NotFound />} />
            <Route path="/" exact element={<Homepage />} />
            <Route path="/menu" exact element={<Menu />} />
            <Route path="/about" exact element={<About />} />
            <Route path="/contact" exact element={<Contact />} />
          </Routes>
          <Footer />
        </Router>

      </div>
      <div id="loadingOverlay" className="loadingOverlay">
        <img src={pizzaImg}></img>
        <h3><i className="fa-solid fa-pizza-slice"></i> Welcome to our Pizzeria</h3>
      </div>
      <NotResponsive />
    </>
  );
}

export default App;