import Margherita from '../assets/Margherita.png';
import Pepperoni from '../assets/Pepperoni.png';
import California from '../assets/California.png';
import Neapolitan from '../assets/Neapolitan.png';
import Chocolate from '../assets/Chocolate.png';
import SpecialPizza from '../assets/Custompizza.png';

export const MenuList = [
    {
        name: "Margherita pizza",
        price: 13.34,
        image: Margherita
    },
    {
        name: "Pepperoni pizza",
        price: 7.32,
        image: Pepperoni
    },
    {
        name: "California pizza",
        price: 25,
        image: California
    },
    {
        name: "Neapolitan pizza",
        price: 5.90,
        image: Neapolitan
    },
    {
        name: "Chocolate pizza",
        price: 26.39,
        image: Chocolate
    },
    {
        name: "Special pizza",
        price: 200,
        image: SpecialPizza
    }
];