import React from 'react'
import Img from '../assets/pizzaSlice.png';

function NotResponsive() {
  return (
    <div className="notResponsive">
      <img src={Img} />
      <p>Sorry, your device does not support our restaurant application.</p>
    </div>
  )
}

export default NotResponsive
