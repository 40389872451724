import React from 'react';
import { MenuList } from '../helpers/MenuList';
import MenuItems from '../components/MenuItems';

function Menu() {
  return (
    <div className="menuContainer">
      <h3>Menu</h3>
      <div className="menuContent">
        {MenuList.map((menuItem, key) => {
          return (
            <MenuItems key={key} image={menuItem.image} name={menuItem.name} price={menuItem.price} />
          )
        })}
      </div>
    </div>
  )
}

export default Menu
