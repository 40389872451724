import React, { useEffect, useState } from 'react';
import pizzaBackground from '../assets/pizzaBackground.jpg';
import {Link} from 'react-router-dom';

function Homepage() {

  return (
    <div className="homeContainer" style={{backgroundImage: `url(${pizzaBackground})`}}>
      <div id="homeContent" className="homeContent">
        <h3><i className="fa-solid fa-pizza-slice"></i> Pizzeria <span>Food.</span></h3>
        <br />
        <Link to="/menu" exact>
          <button>
            <i className="fa-solid fa-bars"></i> Check The Menu
          </button>
        </Link>
      </div>
    </div>
  )
}

export default Homepage