import React from 'react'
import pizzaImage from '../assets/CrispyPizza.jpg'

function Contact() {
  return (
    <div className="contactContainer">
      <div className="pizzaBack" style={{backgroundImage: `url(${pizzaImage})`}}></div>
      <form id="form">
        <h3>Contact us</h3>
        <div>
          <label htmlFor="name">Name</label><br />
          <input name="name" type="text" placeholder="Name" required />
        </div>
        <div>
          <label htmlFor="email">Email</label><br />
          <input name="email" type="email" placeholder="Email" required />
        </div>
        <div>
          <label htmlFor="message">Message</label><br />
          <textarea name="message" placeholder="Message" required></textarea>
        </div>
        <button>Send Message</button>
      </form>
    </div>
  )
}

export default Contact
