import React from 'react'

function About() {
  return (
    <div className="aboutContainer">
      <div className="wrapper">
      </div>
      <p><h3>About us</h3><br />I am a simple programmer with many skills and many features, I created this pizzeria app as my first project in reactjs library, to enhance my skills on it, do not mind the simple design, I have more features, more skills, I can show more, this is not everything I have.</p>
    </div>
  )
}

export default About
