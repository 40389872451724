import React from 'react';
import NotFoundStatus from '../assets/pageNotFoundVector.svg';

function NotFound() {
  return (
    <div className="NotFound">
      <img src={NotFoundStatus} />
      <h3>Page Not Found!</h3>
    </div>
  )
}

export default NotFound
